import { format, isBefore } from 'date-fns';
import mailtoLink from 'mailto-link';
import { DetailedEvent } from '../../../../core/models/detailed-event';
import { useFetchEvent } from '../../../../core/usecases/queries/events/useFetchEvent';
import { fr } from 'date-fns/locale';

export const useDetailedEvent = (id: string) => {
  return useFetchEvent(id, event => {
    event.participants?.sort((p1, p2) => p1.firstName.localeCompare(p2.firstName));
    const isFull = event.nbParticipants === event.maxParticipants;
    const date = formatDate(event.start, event.end);
    const hasEventStarted = isBefore(event.start, new Date());
    const thereAreParticipants = !!event.participants && event.participants.length > 0;
    const thereAreWaitingList = !!event.waitingList && event.waitingList.length > 0;
    const participantsMailTo = getMailTo(event, date);
    return { event, isFull, date, hasEventStarted, thereAreParticipants, participantsMailTo, thereAreWaitingList };
  });
};

function formatDate(start: Date, end: Date): string {
  const day = format(start, 'EEEE dd MMMM', { locale: fr });
  const startHour = format(start, "HH'h'mm");
  const endHour = format(end, "HH'h'mm");
  return `${day} ${startHour} - ${endHour}`;
}

function getMailTo(event: DetailedEvent, date: string) {
  const participants = event.participants;
  if (!participants) {
    return '';
  }
  return mailtoLink({
    bcc: participants.map(participant => participant.email),
    body: `Atelier : ${event.title}\nLieu : ${event.place}\nDate: ${date}`
  });
}
