import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../store/store';

export type JoinEventWaitingListCommand = {
  eventId: string;
};

export const joinEventWaitingList = createAsyncThunk<void, JoinEventWaitingListCommand, ThunkConfig>(
  'events/joinWaitingList',
  (command, { extra: { eventApi } }) => eventApi!.joinWaitingList(command.eventId)
);
