import { createAction, createReducer } from '@reduxjs/toolkit';
import { AsyncStatus } from '../models/async-status';
import { joinEventWaitingList } from '../usecases/join-event-waiting-list/join-event-waiting-list';

export type EventWaitingListState = {
  status: AsyncStatus;
};

const initialState: EventWaitingListState = {
  status: 'idle'
};

export const resetEventWaitingListState = createAction('events/resetEventWaitingList');

export const eventWaitingListReducer = createReducer(initialState, builder => {
  builder.addCase(resetEventWaitingListState, () => initialState);
  builder.addCase(joinEventWaitingList.pending, state => ({ ...state, status: 'pending' }));
  builder.addCase(joinEventWaitingList.rejected, state => ({ ...state, status: 'failed' }));
  builder.addCase(joinEventWaitingList.fulfilled, state => ({ ...state, status: 'succeeded' }));
});
