import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import equal from 'fast-deep-equal';
import { Dependencies } from '../dependencies/dependencies';
import { listenTokenChanges } from '../token/listen-token-changes';
import { logout } from '../usecases/logout/logout';
import { CoinsAdditionState, coinsAdditionReducer } from './coins-additions.state';
import { EventCreationState, eventCreationReducer } from './event-creation.state';
import { EventRegistrationState, eventRegistrationReducer } from './event-registration.state';
import { listenerMiddleware } from './listeners/listener-middleware';
import { LoginState, loginReducer } from './login.state';
import { UserState, userReducer } from './user.state';
import { EventUnregistrationState, eventUnregistrationReducer } from './event-unregistration.state';
import { AccountCreationState, accountCreationReducer } from './account-creation.state';
import { UserUpdateState, userUpdateReducer } from './user-update.state';
import { EventUpdateState, eventUpdateReducer } from './event-update.state';
import {
  ResetPasswordEmailSendingState,
  resetPasswordEmailSendingStateReducer
} from './reset-password-email-sending.state';
import { PasswordResetState, passwordResetStateReducer } from './password-reset.state';
import { eventWaitingListReducer, EventWaitingListState } from './event-join-waiting-list.state';

export type AppState = {
  userState: UserState;
  loginState: LoginState;
  eventCreationState: EventCreationState;
  eventRegistrationState: EventRegistrationState;
  eventWaitingListState: EventWaitingListState;
  eventUnregistrationState: EventUnregistrationState;
  coinsAdditionState: CoinsAdditionState;
  accountCreationState: AccountCreationState;
  userUpdateState: UserUpdateState;
  eventUpdateState: EventUpdateState;
  resetPasswordEmailSendingState: ResetPasswordEmailSendingState;
  passwordResetState: PasswordResetState;
};

const appReducer = combineReducers<AppState>({
  userState: userReducer,
  loginState: loginReducer,
  eventCreationState: eventCreationReducer,
  eventRegistrationState: eventRegistrationReducer,
  eventWaitingListState: eventWaitingListReducer,
  eventUnregistrationState: eventUnregistrationReducer,
  coinsAdditionState: coinsAdditionReducer,
  accountCreationState: accountCreationReducer,
  userUpdateState: userUpdateReducer,
  eventUpdateState: eventUpdateReducer,
  resetPasswordEmailSendingState: resetPasswordEmailSendingStateReducer,
  passwordResetState: passwordResetStateReducer
});

const rootReducer = (state: ReturnType<typeof appReducer>, action: AnyAction) => {
  if (action.type === logout.type) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export function createStore(dependencies: Partial<Dependencies>, preloadedState?: Partial<AppState>) {
  const store = configureStore({
    reducer: rootReducer as typeof appReducer,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ thunk: { extraArgument: dependencies } }).prepend(listenerMiddleware.middleware)
  });
  listenTokenChanges(store);
  return store;
}

export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = AppStore['dispatch'];

export type ThunkConfig<RejectPayload = unknown> = {
  dispatch: AppDispatch;
  state: AppState;
  extra: Partial<Dependencies>;
  rejectValue: RejectPayload;
};

export function createStoreWithLocalStorageSync(dependencies: Partial<Dependencies>, whiteList: (keyof AppState)[]) {
  function saveToLocalStorage(state: Partial<AppState>) {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('redux-persisted-store', serializedState);
    } catch (e) {
      console.warn('Failed to save data in local storage');
    }
  }

  function loadFromLocalStorage() {
    try {
      const serializedState = localStorage.getItem('redux-persisted-store');
      if (!serializedState) {
        return;
      }
      return JSON.parse(serializedState);
    } catch (e) {
      console.warn('Failed to load data from local storage');
      return;
    }
  }

  function createStateToSave(currentState: AppState, whiteList: (keyof AppState)[]) {
    const stateToSave: Partial<AppState> = {};
    for (const subState of whiteList) {
      stateToSave[subState] = currentState[subState] as any;
    }
    return stateToSave;
  }

  const store = createStore(dependencies, loadFromLocalStorage());

  let prevState: Partial<AppState> = createStateToSave(store.getState(), whiteList);

  store.subscribe(() => {
    const stateToSave: Partial<AppState> = createStateToSave(store.getState(), whiteList);
    if (!equal(prevState, stateToSave)) {
      saveToLocalStorage(stateToSave);
      prevState = stateToSave;
    }
  });

  return store;
}
