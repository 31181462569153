import { addDays, addMonths } from 'date-fns';
import Dexie from 'dexie';
import { createDate, setHours } from '../utils/create-date';
import { CategoryEntity } from './entities/category.entity';
import { EventEntity } from './entities/event.entity';
import { UserEntity } from './entities/user.entity';

export function createDatabase() {
  const db = new Dexie('a-petits-pas');
  db.version(4).stores({
    events: '++id',
    categories: '++id',
    users: '++id, [email+password], &email'
  });
  db.on('populate', transaction => {
    transaction.table<CategoryEntity>('categories').bulkAdd([
      { id: 1, title: 'Impro mini pas', maxPersons: 4, color: '#9F87AF', type: 'impro' },
      { id: 2, title: 'Impro petit pas', maxPersons: 6, color: '#79B791', type: 'impro' },
      { id: 3, title: 'Prise de parole niv 1', maxPersons: 5, color: '#7EA3CC', type: 'common' }
    ]);
    const today = setHours(new Date(), '08h00');
    transaction.table<EventEntity>('events').bulkAdd([
      {
        id: 1,
        categoryId: 1,
        description: "Description de l'évènement",
        start: setHours(today, '10h'),
        end: setHours(today, '12h'),
        place: "1 rue de l'église",
        maxParticipants: 4,
        participants: [
          { registeredAt: setHours(today, '10h30'), userId: 3 },
          { registeredAt: setHours(today, '10h34'), userId: 4 },
          { registeredAt: setHours(today, '10h42'), userId: 5 }
        ],
        waitingList: [
          { entryDate: setHours(today, '11h00'), userId: 6, firstName: 'John', email: 'john@mail' },
        ]
      },
      {
        id: 2,
        categoryId: 2,
        description: "Description de l'évènement",
        start: setHours(today, '15h'),
        end: setHours(today, '18h'),
        place: "2 rue de l'église",
        maxParticipants: 6,
        participants: [
          { registeredAt: setHours(today, '16h00'), userId: 3 },
          { registeredAt: setHours(today, '16h01'), userId: 4 },
          { registeredAt: setHours(today, '16h02'), userId: 5 },
          { registeredAt: setHours(today, '16h03'), userId: 6 },
          { registeredAt: setHours(today, '16h04'), userId: 7 }
        ],
        waitingList: [
          { entryDate: setHours(today, '17h00'), userId: 8, firstName: 'John', email: 'john@mail' },
        ]
      },
      {
        id: 3,
        categoryId: 3,
        start: setHours(addDays(today, 1), '13h'),
        end: setHours(addDays(today, 1), '15h30'),
        place: "3 rue de l'église",
        maxParticipants: 5,
        participants: [
          { registeredAt: setHours(addDays(today, 1), '13h30'), userId: 4 },
          { registeredAt: setHours(addDays(today, 1), '13h54'), userId: 8 }
        ],
        waitingList: []
      },
      {
        id: 4,
        categoryId: 3,
        description: "Description de l'évènement",
        start: setHours(addDays(today, 2), '19h'),
        end: setHours(addDays(today, 2), '21h'),
        place: "3 rue de l'église",
        maxParticipants: 4,
        participants: [
          { registeredAt: setHours(addDays(today, 2), '19h15'), userId: 4 },
          { registeredAt: setHours(addDays(today, 2), '19h26'), userId: 8 },
          { registeredAt: setHours(addDays(today, 2), '19h35'), userId: 6 },
          { registeredAt: setHours(addDays(today, 2), '19h44'), userId: 7 }
        ],
        waitingList: [
          { entryDate: setHours(addDays(today, 2), '20h00'), userId: 5, firstName: 'John', email: 'john@mail' },
        ]
      }
    ]);
    transaction.table<UserEntity>('users').bulkAdd([
      {
        id: 1,
        email: 'admin@mail.fr',
        password: 'admin',
        isAdmin: true,
        firstName: 'admin',
        lastName: 'ADMIN',
        registrationDate: createDate('10/01/2022'),
        coins: [],
        availableCategoryIds: []
      },
      {
        id: 2,
        email: 'alex@mail.fr',
        password: 'qwerty',
        isAdmin: false,
        firstName: 'Alex',
        lastName: 'Bois',
        registrationDate: createDate('10/01/2022'),
        coins: [
          {
            id: 1,
            total: 10,
            categoryType: 'impro',
            purchaseDate: addDays(today, -5),
            expirationDate: addMonths(addDays(today, -5), 6),
            name: 'Pack impro',
            history: [
              {
                isCancelation: false,
                date: setHours(addDays(today, -1), '16h56'),
                eventId: 0,
                reason: 'Inscription atelier impro petit pas'
              },
              {
                isCancelation: true,
                date: setHours(addDays(today, -1), '10h24'),
                eventId: 0,
                reason: 'Annulation atelier impro moyen pas'
              },
              {
                isCancelation: false,
                date: setHours(addDays(today, -1), '10h01'),
                eventId: 0,
                reason: 'Inscription atelier impro moyen pas'
              }
            ]
          },
          {
            id: 2,
            total: 1,
            categoryType: 'common',
            purchaseDate: addDays(today, -2),
            expirationDate: addMonths(addDays(today, -2), 3),
            name: "Crédit à l'unité",
            history: []
          },
          {
            id: 3,
            total: 1,
            categoryType: 'common',
            purchaseDate: addDays(today, -2),
            expirationDate: addMonths(addDays(today, -2), 3),
            name: "Crédit à l'unité",
            history: []
          }
        ],
        availableCategoryIds: [1, 2, 3]
      },
      {
        id: 3,
        email: 'eric@mail.fr',
        password: 'qwerty',
        isAdmin: false,
        firstName: 'Eric',
        lastName: 'Chêne',
        registrationDate: createDate('10/01/2022'),
        coins: [],
        // coinsHistory: {
        //   impro: [
        //     { amount: 6, date: addDays(today, -2), reason: "Achat d'un pack impro" },
        //     { amount: -1, date: addDays(today, -1), reason: 'Réservation impro mini pas' },
        //     { amount: -1, date: addHours(addDays(today, -1), 1), reason: 'Réservation impro petit pas' }
        //   ],
        //   common: [
        //     { amount: 1, date: addDays(today, -2), reason: 'Achat unité impro' },
        //     { amount: 1, date: addHours(addDays(today, -2), 1), reason: 'Achat unité impro' },
        //     { amount: 1, date: addDays(today, -1), reason: 'Achat unité impro' },
        //     { amount: 1, date: addHours(addDays(today, -1), 1), reason: 'Achat unité impro' }
        //   ]
        // },
        availableCategoryIds: [1, 2, 3]
      },
      {
        id: 4,
        email: 'johanna@mail.fr',
        password: 'qwerty',
        isAdmin: false,
        firstName: 'Johanna',
        lastName: 'Bouleau',
        registrationDate: createDate('10/01/2022'),
        coins: [],
        availableCategoryIds: [1, 2, 3]
      },
      {
        id: 5,
        email: 'max@mail.fr',
        password: 'qwerty',
        isAdmin: false,
        firstName: 'Max',
        lastName: 'Pain',
        registrationDate: createDate('10/01/2022'),
        coins: [],
        // coinsHistory: {
        //   impro: [{ amount: 1, date: addDays(today, -2), reason: 'Achat unité impro' }],
        //   common: []
        // },
        availableCategoryIds: [1, 2, 3]
      },
      {
        id: 6,
        email: 'camille@mail.fr',
        password: 'qwerty',
        isAdmin: false,
        firstName: 'Camille',
        lastName: 'Leblanc',
        registrationDate: createDate('10/01/2022'),
        coins: [],
        // coinsHistory: {
        //   impro: [],
        //   common: [{ amount: 1, date: addDays(today, -2), reason: 'Achat unité impro' }]
        // },
        availableCategoryIds: [1, 2, 3]
      },
      {
        id: 7,
        email: 'elodie@mail.fr',
        password: 'qwerty',
        isAdmin: false,
        firstName: 'Elodie',
        lastName: 'Noisette',
        registrationDate: createDate('10/01/2022'),
        coins: [],
        // coinsHistory: {
        //   impro: [{ amount: 10, date: addDays(today, -2), reason: "Achat d'un pack impro" }],
        //   common: [{ amount: 10, date: addDays(today, -1), reason: "Achat d'un pack classique" }]
        // },
        availableCategoryIds: [1, 2, 3]
      },
      {
        id: 8,
        email: 'pat@mail.fr',
        password: 'qwerty',
        isAdmin: false,
        firstName: 'Patrick',
        lastName: 'Lechamps',
        registrationDate: createDate('10/01/2022'),
        coins: [],
        // coinsHistory: {
        //   impro: [{ amount: 7, date: addDays(today, -2), reason: "Achat d'un pack impro" }],
        //   common: [{ amount: 9, date: addDays(today, -1), reason: "Achat d'un pack classique" }]
        // },
        availableCategoryIds: [1, 2, 3]
      },
      {
        id: 9,
        email: 'michel@mail.fr',
        password: 'qwerty',
        isAdmin: false,
        firstName: 'Michel',
        lastName: 'Amérique',
        registrationDate: createDate('10/01/2022'),
        coins: [],
        // coinsHistory: {
        //   impro: [{ amount: 3, date: addDays(today, -2), reason: "Achat d'un pack impro" }],
        //   common: [{ amount: 10, date: addDays(today, -1), reason: "Achat d'un pack classique" }]
        // },
        availableCategoryIds: [1]
      },
      {
        id: 10,
        email: 'henri@mail.fr',
        password: 'qwerty',
        isAdmin: false,
        firstName: 'Henri',
        lastName: 'Jaune',
        registrationDate: createDate('10/01/2022'),
        coins: [],
        availableCategoryIds: [1, 2, 3]
      }
    ]);
  });
  return db;
}
