import { Dialog, Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { FC, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetEventUnregistrationState } from '../../../core/store/event-unregistration.state';
import { eventUnregistrationStatusSelector } from '../../../core/store/selectors/event-unregistration-status.selector';
import { AppDispatch } from '../../../core/store/store';
import { unregisterFromEvent } from '../../../core/usecases/unregister-from-event/event-unregistration';
import { useToastStatus } from '../../hooks/use-toast-status';
import { Display } from './Display';
import { Loader } from './Loader';

interface Props {
  show: boolean;
  close: () => void;
  date: string;
  canBeRefunded: boolean;
  title: string;
  eventId: string;
}

export const EventUnregistrationModal: FC<Props> = ({ show, close, date, canBeRefunded, title, eventId }) => {
  const client = useQueryClient();
  const { isLoading, isIdle } = useSelector(eventUnregistrationStatusSelector);
  const dispatch = useDispatch<AppDispatch>();

  const closeDialogByClickingOutside = () => (isLoading ? close() : undefined);

  useToastStatus(eventUnregistrationStatusSelector, {
    success: "Vous avez bien été désinscrit de l'atelier",
    onSuccess: async () => {
      await client.invalidateQueries(['event']);
      await client.invalidateQueries(['coins']);
    },
    finally: () => {
      dispatch(resetEventUnregistrationState());
      close();
    }
  });

  const unregister = () => dispatch(unregisterFromEvent({ eventId }));

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeDialogByClickingOutside}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="div" className="h-stack gap-2 justify-between">
                    <h3 className="text-2xl font-medium leading-6">Se désinscrire - {title}</h3>
                  </Dialog.Title>
                  <div className="mt-2 text-md">
                    <p className="text-gray-500">{date}</p>
                    <br />
                    <Display condition={canBeRefunded}>
                      <p>
                        Nous sommes actuellement à plus de 48h du début de l'atelier, vous serez{' '}
                        <span className="text-success font-bold"> REMBOURSÉ</span> de votre crédit.
                      </p>
                    </Display>
                    <Display condition={!canBeRefunded}>
                      <p>
                        Votre crédit ne vous sera{' '}
                        <span className="text-danger font-bold">PAS REMBOURSÉ</span> pour l'une des raisons suivantes :
                      </p>
                      <p>- Nous sommes actuellement à moins de 48h du début de l'atelier</p>
                      <p>- Votre crédit a expiré</p>
                    </Display>
                    <br />
                  </div>

                  <Display condition={isIdle}>
                    <div className="mt-4 h-stack gap-2 justify-end">
                      <button type="button" className="btn-secondary !py-1 px-2 text-lg" onClick={close}>
                        Retour
                      </button>
                      <button type="button" className="btn !py-1 px-2 text-lg" onClick={unregister}>
                        Confirmer
                      </button>
                    </div>
                  </Display>
                  <Display condition={isLoading}>
                    <div className="mt-4 h-stack justify-center">
                      <Loader />
                    </div>
                  </Display>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
