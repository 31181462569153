import { CreateEventPayload, EventApi, UpdateEventPayload } from '../../apis/event-api';
import { CategoryType } from '../../models/category-type';
import { DetailedEvent } from '../../models/detailed-event';
import { Event } from '../../models/event';
import { HttpClient } from '../http-client';

export class BackendEventApi implements EventApi {
  async fetchAll(): Promise<Event[]> {
    const events = await HttpClient.instance.get('events').json<FetchAllResponse>();

    return events.map(event => ({ ...event, start: new Date(event.start), end: new Date(event.end) }));
  }

  async fetchAllMine(): Promise<Event[]> {
    const events = await HttpClient.instance.get('events/me').json<FetchAllResponse>();

    return events.map(event => ({ ...event, start: new Date(event.start), end: new Date(event.end) }));
  }

  async fetchOne(id: string): Promise<DetailedEvent> {
    const event = await HttpClient.instance.get(`events/${id}`).json<FetchOneResponse>();
    return { ...event, start: new Date(event.start), end: new Date(event.end) };
  }

  async create(payload: CreateEventPayload): Promise<string> {
    const { maxPersons: maxParticipants, ...rest } = payload;
    const requestBody: CreateEventRequestBody = { ...rest, maxParticipants };

    const { id } = await HttpClient.instance.post('events', { json: requestBody }).json<CreateEventResponse>();
    return id;
  }

  async registerToEvent(id: string): Promise<void> {
    await HttpClient.instance.post(`events/${id}/register`);
  }

  async joinWaitingList(id: string): Promise<void> {
    await HttpClient.instance.post(`events/${id}/join-waiting-list`);
  }

  async unregisterFromEvent(id: string): Promise<void> {
    await HttpClient.instance.delete(`events/${id}/unregister`);
  }

  async updateEvent(payload: UpdateEventPayload): Promise<void> {
    const { eventId, ...rest } = payload;
    const requestBody: UpdateEventRequestBody = rest;
    await HttpClient.instance.patch(`events/${eventId}`, { json: requestBody });
  }
}

type FetchAllResponse = BackendEvent[];
type BackendEvent = {
  id: string;
  title: string;
  color: string;
  isFull: boolean;
  start: string;
  end: string;
};

type FetchOneResponse =
  | (BackendDetailedEventBase & { isUserRegistered: boolean })
  | (BackendDetailedEventBase & { participants: { id: string; firstName: string; lastName: string; email: string }[] });

type BackendDetailedEventBase = {
  id: string;
  title: string;
  color: string;
  place: string;
  nbParticipants: number;
  maxParticipants: number;
  start: string;
  end: string;
  categoryType: CategoryType;
  description?: string;
};

type CreateEventRequestBody = {
  categoryId: string;
  place: string;
  maxParticipants: number;
  start: Date;
  end: Date;
  description?: string;
};

type CreateEventResponse = {
  id: string;
};

type UpdateEventRequestBody = {
  place?: string;
  description?: string | null;
};
