import { FC, useState } from 'react';
import { BsCalendarDateFill, BsPinMapFill } from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';
import { MdModeEditOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsAdmin } from '../../../hooks/useIsAdmin';
import { Display } from '../../components/Display';
import { Error } from '../../components/Error';
import { EventRegistrationModal } from '../../components/EventRegistrationModal';
import { EventUnregistrationModal } from '../../components/EventUnregistrationModal';
import { Loader } from '../../components/Loader';
import './Event.css';
import { EventInformation } from './EventInformation';
import { useDetailedEvent } from './useDetailedEvent';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../core/store/store';
import { joinEventWaitingList } from '../../../../core/usecases/join-event-waiting-list/join-event-waiting-list';
import { useToastStatus } from '../../../hooks/use-toast-status';
import { eventWaitingListStatusSelector } from '../../../../core/store/selectors/event-waiting-list-status.selector';
import { useQueryClient } from '@tanstack/react-query';
import { resetEventWaitingListState } from '../../../../core/store/event-join-waiting-list.state';

export const Event: FC = () => {
  const { id } = useParams<'id'>();
  const result = useDetailedEvent(id!);

  if (result.isFetching) {
    return <Loader />;
  }

  if (result.isError || !result.data) {
    return <Error />;
  }

  return <EventContent {...result.data} />;
};

type Props = NonNullable<ReturnType<typeof useDetailedEvent>['data']>;

const EventContent: FC<Props> = ({
  event,
  isFull,
  date,
  hasEventStarted,
  thereAreParticipants,
  thereAreWaitingList,
  participantsMailTo
}) => {
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  const [isEventRegistrationModalOpen, setIsEventRegistrationModalOpen] = useState(false);
  const openEventRegistrationModal = () => setIsEventRegistrationModalOpen(true);
  const closeEventRegistrationModal = () => setIsEventRegistrationModalOpen(false);

  const [isEventUnregistrationModalOpen, setIsEventUnregistrationModalOpen] = useState(false);
  const openEventUnregistrationModal = () => setIsEventUnregistrationModalOpen(true);
  const closeEventUnregistrationModal = () => setIsEventUnregistrationModalOpen(false);

  const dispatch = useDispatch<AppDispatch>();
  const joinWaitingList = () => dispatch(joinEventWaitingList({ eventId: event.id }));

  const { isLoading: isLoadingWaitingList, isIdle: isWaitingListIdle } = useSelector(eventWaitingListStatusSelector);

  const client = useQueryClient();
  useToastStatus(eventWaitingListStatusSelector, {
      success: "Votre êtes maintenant en liste d'attente de cet atelier",
      onSuccess: async () => {
        await client.invalidateQueries(['event']);
      },
      finally: () => {
        dispatch(resetEventWaitingListState());
      }
    });

  return (
    <>
      <div className="w-full p-4">
        <h1 className="text-4xl font-medium underline mb-10" style={{ textDecorationColor: event.color }}>
          {event.title}
        </h1>

        <div className="h-stack-wrap gap-10 mb-10">
          <EventInformation Icon={BsCalendarDateFill} title="Date et heure" content={date} />
          <EventInformation Icon={BsPinMapFill} title="Lieu" content={event.place} />
          <EventInformation
            Icon={HiUserGroup}
            title="Participants"
            content={`${event.nbParticipants} / ${event.maxParticipants}`}
          />
        </div>

        <Display condition={event.description}>
          <div className="mb-10">
            <h2 className="text-2xl mb-3">A propos de cet évènement</h2>
            <p>{event.description}</p>
          </div>
        </Display>

        <Display condition={isAdmin}>
          <div className="mb-10">
            <h2 className="text-2xl mb-3">Participants</h2>

            <Display condition={thereAreParticipants}>
              <table className="participants-table mb-3">
                <thead>
                  <tr>
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {event.participants?.map(participant => (
                    <tr key={participant.id} onClick={() => navigate('/adherents/' + participant.id)}>
                      <td>{participant.firstName}</td>
                      <td>{participant.lastName}</td>
                      <td>{participant.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <a href={participantsMailTo} className="text-link underline">
                Envoyer un email aux participants
              </a>
            </Display>
            <Display condition={!thereAreParticipants}>Aucun participant pour le moment</Display>
          </div>
        </Display>

        <Display condition={isAdmin}>
          <div className="mb-10">
            <h2 className="text-2xl mb-3">Liste d'attente</h2>

            <Display condition={thereAreWaitingList}>
              <table className="participants-table mb-3">
                <thead>
                  <tr>
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {event.waitingList?.map(user => (
                    <tr key={user.id} onClick={() => navigate('/adherents/' + user.id)}>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Display>
            <Display condition={!thereAreWaitingList}>Liste d'attente vide</Display>
          </div>
        </Display>

        <Display condition={!isAdmin}>
          <div className="mt-4">
            <Display condition={hasEventStarted}>
              <p>
                <span className="text-lg font-bold">L'atelier est passé</span>
              </p>
            </Display>
            <Display condition={!hasEventStarted}>
              <Display condition={event.isUserRegistered}>
                <p>
                  <span className="text-lg font-bold">Vous êtes inscrit à cet atelier</span>
                  <br />
                </p>
                <br />
                <button type="button" className="btn-danger !py-2 px-3 text-xl" onClick={openEventUnregistrationModal}>
                  Annuler ma venue
                </button>
              </Display>
              <Display condition={!event.isUserRegistered && isFull}>
                <span className="text-lg font-bold">L'atelier est complet</span>
                <br />
                <Display condition={!event.isUserInWaitingList && isWaitingListIdle}>
                  <button type="button" className="btn mt-4 !py-2 px-3 text-xl" onClick={joinWaitingList}>
                    Rejoindre la liste d'attente
                  </button>
                </Display>
                <Display condition={isLoadingWaitingList}>
                  <div className="mt-4 h-stack justify-center">
                    <Loader />
                  </div>
                </Display>
                <Display condition={event.isUserInWaitingList}>
                  <span>Tu es en liste d'attente pour cet atelier. Un mail te sera envoyé dès qu'une place se libère.</span>
                </Display>
              </Display>
              <Display condition={!event.isUserRegistered && !isFull}>
                <button type="button" className="btn !py-2 px-3 text-xl" onClick={openEventRegistrationModal}>
                  S'inscrire
                </button>
              </Display>
            </Display>
          </div>
        </Display>
      </div>
      <Display condition={isAdmin}>
        <button className="btn-action" disabled={hasEventStarted} onClick={() => navigate('modifier')}>
          <MdModeEditOutline />
        </button>
      </Display>
      <EventRegistrationModal
        show={isEventRegistrationModalOpen}
        close={closeEventRegistrationModal}
        date={date}
        categoryType={event.categoryType}
        title={event.title}
        eventId={event.id}
      />
      <EventUnregistrationModal
        show={isEventUnregistrationModalOpen}
        close={closeEventUnregistrationModal}
        date={date}
        canBeRefunded={!!event.canBeRefunded}
        title={event.title}
        eventId={event.id}
      />
    </>
  );
};
