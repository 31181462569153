import { isBefore, subDays } from 'date-fns';
import Dexie from 'dexie';
import { CreateEventPayload, EventApi, UpdateEventPayload } from '../../apis/event-api';
import { DetailedEvent } from '../../models/detailed-event';
import { Event } from '../../models/event';
import { CategoryEntity } from '../../storage/entities/category.entity';
import { EventEntity } from '../../storage/entities/event.entity';
import { CoinEntity, UserEntity } from '../../storage/entities/user.entity';
import { TokenManager } from '../../token/token-manager';
import { promisedTimeout } from '../../utils/promised-timeout';

export class DexieEventApi implements EventApi {
  public constructor(private dexie: Dexie) {}

  public async fetchAll(): Promise<Event[]> {
    const currentUser = await this.getCurrentUser();
    const isCurrentUserAdmin = currentUser.isAdmin;

    const events = (await this.dexie.table<EventEntity>('events').toArray()).filter(
      event => isCurrentUserAdmin || currentUser.availableCategoryIds.includes(event.categoryId)
    );

    return Promise.all(
      events.map(async event => {
        const category = await this.dexie.table<CategoryEntity>('categories').get(event.categoryId);

        if (!category) {
          throw new Error(`Category ${event.categoryId} is not found`);
        }

        return {
          id: event.id.toString(),
          start: event.start,
          end: event.end,
          isFull: event.participants.length >= event.maxParticipants,
          title: category.title,
          color: category.color
        };
      })
    );
  }

  public async fetchAllMine(): Promise<Event[]> {
    const events = (await this.dexie.table<EventEntity>('events').toArray()).filter(event =>
      event.participants.some(participant => participant.userId === this.currentUserId)
    );

    return Promise.all(
      events.map(async event => {
        const category = await this.dexie.table<CategoryEntity>('categories').get(event.categoryId);

        if (!category) {
          throw new Error(`Category ${event.categoryId} is not found`);
        }

        return {
          id: event.id.toString(),
          start: event.start,
          end: event.end,
          isFull: event.participants.length >= event.maxParticipants,
          title: category.title,
          color: category.color
        };
      })
    );
  }

  public async fetchOne(id: string): Promise<DetailedEvent> {
    const event = await this.dexie.table<EventEntity>('events').get(+id);

    if (!event) {
      throw new Error(`Event ${id} is not found`);
    }

    const category = await this.dexie.table<CategoryEntity>('categories').get(+event.categoryId);

    if (!category) {
      throw new Error(`Category ${event.categoryId} is not found`);
    }

    const user = await this.getCurrentUser();

    if (!user.isAdmin && !user.availableCategoryIds.includes(category.id)) {
      throw new Error('User does not have permissions to access this event');
    }

    return {
      id: event.id.toString(),
      start: event.start,
      end: event.end,
      place: event.place,
      nbParticipants: event.participants.length,
      maxParticipants: event.maxParticipants,
      title: category.title,
      description: event.description,
      color: category.color,
      categoryType: category.type,
      isUserRegistered: event.participants.some(participant => participant.userId === this.currentUserId),
      participants: await this.retrieveParticipantsIfUserIsAdmin(event.participants)
    };
  }

  private async retrieveParticipantsIfUserIsAdmin(
    partialParticipants: EventEntity['participants']
  ): Promise<{ id: string; firstName: string; lastName: string; email: string }[]> {
    const isCurrentUserAdmin = await this.checkIsCurrentUserAdmin();

    if (!isCurrentUserAdmin) {
      return [];
    }

    return Promise.all(
      partialParticipants.map(async ({ userId }) => {
        const user = await this.getUser(userId);
        return {
          id: user.id.toString(),
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email
        };
      })
    );
  }

  public async create(payload: CreateEventPayload): Promise<string> {
    const id = await this.dexie.table<Omit<EventEntity, 'id'>>('events').add({
      categoryId: +payload.categoryId,
      maxParticipants: payload.maxPersons,
      participants: [],
      waitingList: [],
      place: payload.place,
      start: payload.start,
      end: payload.end,
      description: payload.description
    });

    await promisedTimeout(2000);

    return id.toString();
  }

  public async registerToEvent(id: string): Promise<void> {
    const eventId: number = +id;
    const userId = this.currentUserId;
    const event = await this.dexie.table<EventEntity>('events').get(eventId);

    if (!event) {
      throw new Error('Event not found');
    }

    await this.addEntryToCoinHistory(event, userId);

    await this.addUserToEvent(event, userId, eventId);

    await this.removeUserFromWaitingList(event, userId, eventId);

    await promisedTimeout(1500);
  }

  public async joinWaitingList(id: string): Promise<void> {
    const eventId: number = +id;
    const userId = this.currentUserId;
    const event = await this.dexie.table<EventEntity>('events').get(eventId);

    if (!event) {
      throw new Error('Event not found');
    }

    await this.addUserToWaitingList(event, userId, eventId);

    await promisedTimeout(1500);
  }

  private async addUserToEvent(event: EventEntity, userId: number, eventId: number) {
    const participants = [...event.participants, { userId, registeredAt: new Date() }];

    await this.dexie.table<EventEntity>('events').update(eventId, { participants });
  }

  private async addUserToWaitingList(event: EventEntity, userId: number, eventId: number) {
    const waitingList = [...event.waitingList, { userId, entryDate: new Date() }];

    await this.dexie.table<EventEntity>('events').update(eventId, { waitingList });
  }

  private async addEntryToCoinHistory(event: EventEntity, userId: number) {
    const category = (await this.dexie.table<CategoryEntity>('categories').get(event.categoryId))!;

    const user = (await this.dexie.table<UserEntity>('users').get(userId))!;

    const coinWithEarliestExpirationDate = user.coins
      .filter(coin => coin.categoryType === category.type)
      .reduce((prev, cur) => {
        if (!prev) {
          return cur;
        }
        return isBefore(cur.expirationDate, prev.expirationDate) ? cur : prev;
      }, undefined as CoinEntity | undefined);

    if (!coinWithEarliestExpirationDate) {
      throw new Error('No coins');
    }

    const updatedCoins: CoinEntity[] = user.coins.map(coin => {
      if (coin.id !== coinWithEarliestExpirationDate.id) {
        return coin;
      }
      return {
        ...coin,
        history: [
          ...coin.history,
          { date: new Date(), eventId: event.id, isCancelation: false, reason: 'Inscription atelier ' + category.title }
        ]
      };
    });

    await this.dexie.table<UserEntity>('users').update(userId, { coins: updatedCoins });
  }

  public async updateEvent(payload: UpdateEventPayload): Promise<void> {
    await promisedTimeout(600);
    const eventId: number = +payload.eventId;
    const toUpdate: Partial<EventEntity> = {
      place: payload.place,
      description: payload.description ?? undefined
    };
    await this.dexie.table<EventEntity>('events').update(eventId, toUpdate);
  }

  public async unregisterFromEvent(id: string): Promise<void> {
    const eventId: number = +id;
    const event = await this.dexie.table<EventEntity>('events').get(eventId);
    const user = await this.getCurrentUser();

    if (!event) {
      throw new Error('Event not found');
    }

    const participant = await this.removeParticipantFromEvent(event, user, eventId);

    if (isBefore(new Date(), subDays(event.start, 2))) {
      await this.refundCoin(user, eventId, participant);
    }
  }

  private async removeParticipantFromEvent(event: EventEntity, user: UserEntity, eventId: number) {
    const index = event.participants.findIndex(p => p.userId === user.id);
    const participant = event.participants[index];
    event.participants.splice(index, 1);

    await this.dexie.table<EventEntity>('events').update(eventId, { participants: event.participants });
    return participant;
  }

  private async refundCoin(user: UserEntity, eventId: number, participant: EventEntity['participants'][number]) {
    function areDateEqual(dateA: Date, dateB: Date) {
      return Math.abs(dateA.getTime() - dateB.getTime()) < 5000;
    }

    const coinIndex = user.coins.findIndex(coin =>
      coin.history.some(entry => entry.eventId === eventId && areDateEqual(entry.date, participant.registeredAt))
    );

    user.coins[coinIndex].history.push({
      date: new Date(),
      eventId,
      isCancelation: true,
      reason: 'Annulation atelier'
    });

    await this.dexie.table<UserEntity>('users').update(user.id, { coins: user.coins });
  }

  private async removeUserFromWaitingList(event: EventEntity, userId: number, eventId: number) {
    const index = event.waitingList.findIndex(p => p.userId === userId);
    if (index >= 0) {
      event.waitingList.splice(index, 1);
      await this.dexie.table<EventEntity>('events').update(eventId, { waitingList: event.waitingList });
    }
  }

  private get currentUserId(): number {
    return +TokenManager.token;
  }

  private getCurrentUser(): Promise<UserEntity> {
    return this.getUser(this.currentUserId);
  }

  private async getUser(id: number): Promise<UserEntity> {
    const currentUser = await this.dexie.table<UserEntity>('users').get(id);

    if (!currentUser) {
      throw new Error('Current user not found');
    }

    return currentUser;
  }

  private async checkIsCurrentUserAdmin(): Promise<boolean> {
    const user = await this.getCurrentUser();
    return user.isAdmin;
  }
}
